import { useState } from 'react';
import { Box, HStack, VStack, Stack, Text, Button, ButtonGroup, useDisclosure, Avatar, AvatarBadge, AvatarGroup} from '@chakra-ui/react'
import {CountDown} from '@/Components/Helpers'
import { Head, Link, useForm } from '@inertiajs/react';
import Bid from '@/Pages/Pages/Bid/Bid'

const NFTItem = ({nfts, height, column, balance, user_id, from, auth}) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [bidNFT, setBidNFT] = useState(null)

    const {get} = useForm({})

    const NFTDetail = (id) => {
        return get(route('nft', {id}))
    }

    const CreatorDetail = (id) => {
        return get(route('creator', {id}))
    }

    return  (

       <>
       
        {
            nfts.map(nft => {
                return (
                    <div className={column} key={nft.trx_id}>
                            <Box marginBottom={2} backgroundSize={'cover'} backgroundPosition={'center'} backgroundImage={`url(${window.location.origin}/${nft.art})`} overflowX={'hidden'} height={height} justifyContent={'flex-end'} backgroundColor={'#f8ebde'} borderRadius={40}>
                
                                    <VStack height={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                                                
                                        <HStack marginTop={5} justifyContent={'space-between'} alignItems={'center'} width={"100%"}>
                                            <Box paddingLeft={5}>
                                                <HStack marginTop={'auto'} alignItems={'flex-start'} width={'100%'}>
                                                    <Box backgroundColor={'#637eeb'} borderRadius={100} padding={1}>
                                                        <img width={24} style={{filter: 'brightness(4)'}} src={`${window.location.origin}/images/icons/eth-logo.png`} alt="" />
                                                    </Box>
                                                </HStack>
                                            </Box>
                                            <HStack onClick={() => { CreatorDetail(nft.username) }} cursor={'pointer'} spacing={1} p={1} width={'auto'} backgroundColor={'#0e0e0e9e'} backdropFilter={'blur(10px)'} borderRadius={'20px'}>   
                                                <Avatar size={'sm'} backgroundColor={'#637eeb'} color={'#fff'} name={nft.username} src={window.location.origin+'/'+nft.owner_image} />
                                                <Text fontSize={15} fontWeight={'bold'} className='text-white'>{nft.username}</Text>
                                            </HStack>
                                            <Box paddingRight={5}>
                                                <HStack marginTop={'auto'} alignItems={'center'} width={'100%'}>
                                                    <Box onClick={() => {NFTDetail(nft.trx_id)}} cursor={'pointer'} backgroundColor={'#637eeb'} margin={'auto'} textAlign={'center'} height={35} width={35} borderRadius={100} padding={1}>
                                                        <i style={{fontSize: 17}} className="ri-external-link-fill text-white"></i>
                                                    </Box>
                                                </HStack>
                                            </Box>
                                        </HStack>
                
                                        
                                        <VStack justifyContent={'flex-end'} height={'100%'} padding={2.5} width={'100%'}>
                                            <Box padding={4} height={120} width={'100%'} backgroundColor={'#0e0e0e9e'} backdropFilter={'blur(10px)'} borderRadius={'20px 20px 30px 30px'}>
                                                <HStack height={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                                                    <Box>
                                                        {
                                                            nft?.is_listed ? (
                                                                <VStack spacing={2}>
                                                                    <Text className={'text-white'} fontSize={18} fontFamily={'Boucle2'}>{nft?.listing?.best_offer > 0 ? 'Best Offer' : 'Current Bid'}</Text>
                                                                    <Text className={'brand-text'} fontSize={22} fontFamily={'Cosmata Bold'}>{nft?.listing?.best_offer > 0 ? nft?.listing?.best_offer : nft?.listing?.amount > 0 ? nft?.listing?.amount : 0.00 } ETH</Text>
                                                                </VStack>
                                                            ) : (
                                                                <VStack spacing={2}>
                                                                    <Text className={'text-white'} fontSize={18} fontFamily={'Boucle2'}>Current Bid</Text>
                                                                    <Text className={'brand-text'} fontSize={22} fontFamily={'Cosmata Bold'}>0 ETH</Text>
                                                                </VStack>
                                                            )
                                                        }
                                                    </Box>
                                                    
                                                    <Box>
                                                        <VStack spacing={1}>
                                                            <Text className={'text-white'} fontSize={15} fontFamily={'Cosmata Regular'}>

                                                                {
                                                                    nft?.is_listed == true ? (
                                                                        <CountDown fontSize={'15px'} starting_date_time={nft?.listing?.starting_date_time} ending_date_time={nft?.listing?.ending_date_time} />
                                                                    ) : (
                                                                        <>
                                                                             00h : 00m : 00s
                                                                        </>
                                                                    )
                                                                }
                                                                

                                                            </Text>
                                                            {
                                                                from ? (
                                                                    <Button onClick={() => {

                                                                        if(auth?.user){
                                                                            if(auth?.user?.id != nft.user_id && nft.is_listed){
                                                                                setBidNFT(nft)
                                                                                onOpen()
                                                                            }else{
                                                                                NFTDetail(nft.trx_id)
                                                                            }          
                                                                        }else{
                                                                            window.location.href = '/login'
                                                                        }

                                                                    }} size={'sm'} _hover={{background: 'none'}} _focus={{outline: 0}} width={'100%'} fontSize={15} borderRadius={8} fontFamily={'Boucle2'} className="brand-bg text-dark">
                                                                        { auth?.user ? auth?.user?.id != nft.user_id ? 'Bid Now' : 'View' : 'Login' }
                                                                    </Button>
                                                                ) : (
                                                                    <Button onClick={() => {
                                                                        if(auth.user.id  != nft.user_id && nft.is_listed){
                                                                            setBidNFT(nft)
                                                                            onOpen()
                                                                        }else{
                                                                            NFTDetail(nft.trx_id)
                                                                        }
                                                                    }} size={'sm'} _hover={{background: 'none'}} _focus={{outline: 0}} width={'100%'} fontSize={15} borderRadius={8} fontFamily={'Boucle2'} className="brand-bg text-dark">
                                                                        { auth.user.id != nft.user_id ? 'Bid Now' : 'View'}
                                                                    </Button>
                                                                )
                                                            }
                                                        </VStack>
                                                    </Box>
                                                </HStack>
                                            </Box>
                                        </VStack>
                
                                    </VStack>
                
                            </Box>
                        
                    </div>
                )
            })
        }   

        
        {
            bidNFT ? (
                <Bid isOpen={isOpen} onOpen={onOpen} onClose={onClose} nft={bidNFT} balance={balance} listing={bidNFT?.listing} />
            ) : (<></>)
        }

       </>

    )
    
}

export default NFTItem